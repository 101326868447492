import React from "react"
import "./App.scss"

const App: React.FC = () => {
    return (
        <div className="App">
            <h1>
                <a
                    href="https://github.com/jackholmes1010"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src="/gh.png" alt="Logo" />
                    <span>github.com/jackholmes1010</span>
                </a>
            </h1>

            <a
                href="https://data.typeracer.com/pit/profile?user=spiral64&ref=badge"
                target="_top"
            >
                <img
                    src="https://data.typeracer.com/misc/badge?user=spiral64"
                    alt="TypeRacer.com scorecard for user spiral64"
                />
            </a>
            <div />
        </div>
    )
}

export default App
